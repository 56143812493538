.grid
{
	display:               grid;
	grid-template-columns: repeat(auto-fill, 5%);
}

.block
{
	position: relative;
	width:    100%;
}

.block:after
{
	content:        '';
	display:        block;
	padding-bottom: 100%;
}


.deals
{
}

.deals .deal
{
	display: inline-flex;
	border-radius: 10%;
	border:  2px solid #cfd8dc;
	padding: 2px;
	margin:  2px;
}
.deals .deal .block:not(:first-child)
{
	/* margin is: 2*(deal.padding + deal.margin + deal.border)*/
	margin-left: 12px;
}

.deals .deal .block
{
	width: 40px;
}



.deals .block .content
{
	position: absolute;
	width:    calc(100% - 2px);
	height:   calc(100% - 2px);
	margin:   1px;
}

.deals .block .content         { border-radius:    20%;     }
.deals .block .content.info    { background-color: #33b5e5; }
.deals .block .content.success { background-color: #00C851; }
.deals .block .content.danger  { background-color: #ff4444; }
.deals .block .content.warning { background-color: #ffbb33; }
.deals .block .content.empty   { background-color: #cfd8dc; }
