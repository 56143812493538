.grid
{
	display:               grid;
	position:              relative;
	width:                 100%;
	grid-template-columns: repeat(auto-fill, 5%);
}

.grid .spinner-overlay
{
	position:         absolute;
	display:          flex;
	justify-content:  center;
	align-items:      center;
	width:            100%;
	z-index:          100;
}

.grid .spinner-overlay:after
{
	content:        '';
	display:        block;
	padding-bottom: 100%;
}

.grid .block
{
	position: relative;
	width:    100%;
}

.grid .block:after
{
	content:        '';
	display:        block;
	padding-bottom: 100%;
}

.grid .block .content
{
	position: absolute;
	width:    calc(100% - 2px);
	height:   calc(100% - 2px);
	margin:   1px;
}

.grid .block          { border-radius:    20%;     }
.grid .block .info    { background-color: #33b5e5; }
.grid .block .success { background-color: #00C851; }
.grid .block .danger  { background-color: #ff4444; }
.grid .block .warning { background-color: #ffbb33; }

.pagination
{
	justify-content: center;
}
